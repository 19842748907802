//type rfce and it autocompletes
import { useState } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'
import "../styles/Navbar.css"

//to get an image from assets, it's
import Logo from "../assets/headshot.png"
import ReorderIcon from '@mui/icons-material/Reorder';

function Navbar() {

  const [openLinks, setOpenLinks] = useState(false);

  const toggleNavbar = () => {
    setOpenLinks(!openLinks)
  }


  return (
    <div className="navbar">
        <div className="leftSide" id = {openLinks ? "open" : "close"}>
            <img src={Logo} />
            <div className="hiddenLinks">
              <Link to="/"> Home</Link>
              <Link to="/resume"> Experience</Link>
              <Link to="/about"> About</Link>
              <Link to="/contact"> Contact</Link>
            </div>
        </div>
        <div className="rightSide">
           <Link to="/"> Home</Link>
           <Link to="/resume"> Experience</Link>
           <Link to="/about"> About</Link>
           <Link to="/contact"> Contact</Link>
           <button onClick={toggleNavbar}>
            <ReorderIcon />
            </button>
        </div>
      
    </div>
  )
}

export default Navbar
